import React, {
    CompositionEventHandler,
    useContext,
    useEffect,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addChat } from '../redux/state/chat';
import format from 'date-fns/format';
import { useSocket } from '../hook/useSocket';
import {
    ChatInterface,
    DialogInterface,
    JsFuntionInterface,
    ReduxFunctionInterface,
    SendMessageEventInterface,
} from '../utils/interfaces';
import Button from '../component/Button';
import { useCacheService } from '../hook/useCacheService';
import classNames from 'classnames';
import { filter } from '../utils/chatFilter';
import { useTranslation } from 'react-i18next';
import { addDialog, removeDialog } from '../redux/state/dialog';
import { editSession, editSessions } from '../redux/state/session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faStar, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { editStatus } from '../redux/state/status';
import Swal from 'sweetalert2';
import { getOS } from '../utils/navigator';
import IntentSuggestionModal from '../element/Modal/IntentSuggestionModal';
import e from 'express';
import { post } from '../utils/rest';
import { error } from '../utils/console';
import { checkInputMessage } from '../helper/common';
import TypingMessage from './TypingMessage';

interface Props {
    debug?: string;
}

const Footer = (props: Props) => {
    const [inputMessage, setInputMessage] = useState<string>('');

    const [filteredMessage, setFilteredMessage] = useState<string>('');

    const [messageToSend, setMessageToSend] = useState<string>('');

    const [isTyping, setIsTyping] = useState<boolean>(false);

    const [typingTimer, setTypingTimer] = useState<any>(null);

    const [intentSent, setIntentSent] = useState(0);

    const [isValidInputMessage, setIsValidInputMessage] = useState(false);


    const { t } = useTranslation();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const socket = useSocket();

    const dispatch = useDispatch();
    const cacheService = useCacheService();

    const isChatAlive = useSelector(
        (state: { status: { isChatAlive: Boolean } }) =>
            state.status.isChatAlive
    );

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const botId = useSelector(
        (state: { session: { botId: string } }) => state.session.botId
    );

    const acctId = useSelector(
        (state: { session: { acctId: string } }) => state.session.acctId
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const laAffinityToken = useSelector(
        (state: { session: { laAffinityToken: string } }) =>
            state.session.laAffinityToken
    );

    const laSessionKey = useSelector(
        (state: { session: { laSessionKey: string } }) =>
            state.session.laSessionKey
    );

    const chatMode = useSelector(
        (state: { session: { chatMode: string } }) => state.session.chatMode
    );

    const filterStrings = useSelector(
        (state: { chatFilter: { filterStrings: string } }) =>
            state.chatFilter.filterStrings
    );

    const filters = useSelector(
        (state: { chatFilter: { filters: object } }) => state.chatFilter.filters
    );

    const regExp =
        filterStrings.length > 0
            ? new RegExp(filterStrings.slice(1), 'g')
            : null;

    const typingBarPlaceHolder = useSelector(
        (state: { systemMessages: { typing_bar: { message: string } } }) =>
            state.systemMessages.typing_bar.message
    );

    const isChatRatingSubmitted = useSelector(
        (state: { settings: { isChatRatingSubmitted: boolean } }) =>
            state.settings.isChatRatingSubmitted
    );

    const botCloseButtonColor = useSelector(
        (state: { settings: { botCloseButtonColor: string } }) =>
            state.settings.botCloseButtonColor
    );

    const botCloseButtonText = useSelector(
        (state: { settings: { botCloseButtonText: string } }) =>
            state.settings.botCloseButtonText
    );

    const botColor = useSelector(
        (state: { settings: { botColor: string } }) => state.settings.botColor
    );

    const chatRatingDone = useSelector(
        (state: { session: { chatRatingDone: boolean } }) =>
            state.session.chatRatingDone
    );

    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const botChatBgColor = useSelector(
        (state: { settings: { botChatBgColor: string } }) =>
            state.settings.botChatBgColor
    );

    const botLanguage = useSelector(
        (state: { settings: { botLanguage: string } }) =>
            state.settings.botLanguage
    );

    const isStartBot = useSelector(
        (state: { session: { isStartBot: boolean } }) =>
            state.session.isStartBot
    );

    const respondedToBot = useSelector(
        (state: { session: { respondedToBot: boolean } }) =>
            state.session.respondedToBot
    );

    const respondedToAgent = useSelector(
        (state: { session: { respondedToAgent: boolean } }) =>
            state.session.respondedToAgent
    );

    const chatCountReason = useSelector(
        (state: { session: { chatCountReason: string } }) =>
            state.session.chatCountReason
    );

    const intentInfo = useSelector(
        (state: { settings: { intentInfo: object } }) =>
            state.settings.intentInfo
    );

    const processor = useSelector(
        (state: { settings: { processor: number } }) =>
            state.settings.processor
    );

    const isIntentSuggestionModal = useSelector(
        (state: { status: { isIntentSuggestionModal: Boolean } }) =>
            state.status.isIntentSuggestionModal
    );

    const intentChoice = useSelector(
        (state: { session: { intentChoice: string } }) =>
            state.session.intentChoice
    );

    const apiPath = useSelector(
        (state: { session: { apiPath: string } }) =>
            state.session.apiPath
    );

    const isBotTyping = useSelector(
        (state: { status: { isBotTyping: Boolean } }) =>
            state.status.isBotTyping
    );

    const openIntentSuggestionModal = () => {
        if (!isIntentSuggestionModal) {
            dispatch(
                editStatus({
                    name: 'isIntentSuggestionModal',
                    value: true,
                })
            );
        }
    };

    const closeIntentSuggestionModal = () => {
        if (isIntentSuggestionModal) {
            dispatch(
                editStatus({
                    name: 'isIntentSuggestionModal',
                    value: false,
                })
            );
        }
        if (intentChoice != '') {
            dispatch(
                editSession({
                    name: 'intentChoice',
                    value: '',
                })
            );
        }
    };

    useEffect(() => {
        console.log('chatcount respond', respondedToBot, respondedToAgent);
        cacheService.setCacheByField(
            sessionId,
            'respondedToBot',
            respondedToBot
        );
        cacheService.setCacheByField(
            sessionId,
            'respondedToAgent',
            respondedToAgent
        );
        cacheService.setCacheByField(sessionId, 'isStartBot', isStartBot);
    }, [respondedToBot, respondedToAgent]);

    let iconPath = require('../assets/send (2).png');

    const realign = () => {
        // setTimeout(() => {
        //     window.scrollTo(0, 0);
        //     document.body.scrollTop = 0;
        // }, 500);
    };

    const closeDialog = (type: string) => {
        dispatch(removeDialog(type));
    };

    const addUserMessage = async () => {
        // setTimeout(() => {
        //     console.log('filteredMessage', filteredMessage);
        // }, 500);
        if (
            filteredMessage != '' &&
            filteredMessage.trim() != '' &&
            messageToSend.trim() != ''
        ) {
            closeDialog('timeoutWarning');
            const content = {
                owner: 'user',
                name: username,
                type: 'text',
                message: {
                    content: messageToSend,
                },
                displayTime: format(new Date(), 'hh:mmaa'),
                createdBy: new Date().getTime(),
            } as ChatInterface;
            cacheService.pushCache(
                sessionId + '_chats',
                JSON.stringify(content)
            );
            dispatch(addChat(content));
            if (chatMode === 'bot') {
                let data = {
                    message: filteredMessage,
                    type: 'message',
                    username: username,
                    chatId: chatId,
                    customer_lang_culture:
                        botLanguage.toLowerCase() === 'chinese'
                            ? 'zh-cn'
                            : botLanguage.toLowerCase() === 'thai'
                            ? 'th-th'
                            : botLanguage.toLowerCase() === 'vietnamese'
                            ? 'vi-vn'
                            : botLanguage.toLowerCase() === 'vietnam'
                            ? 'vi-vn'
                            : botLanguage.toLowerCase() === 'english'
                            ? 'en'
                            : botLanguage.toLowerCase(),
                    operating_system: getOS(navigator),
                    screen_resolution:
                        window.screen.width + '*' + window.screen.height,

                    platform: 'mario',
                    group_name: '',
                    processor: processor
                };
                if (!respondedToBot && chatCountReason != 'respondedToBot') {
                    if (!isStartBot) {
                        dispatch(
                            editSession({
                                name: 'isStartBot',
                                value: true,
                            })
                        );
                    };
                    dispatch(
                        editSessions({
                            respondedToBot: true,
                            chatCountReason: 'respondedToBot',
                        })
                    );
                }
                await dispatch(editStatus({ name: 'isBotTyping', value: true }));
                socket.sendMessageEvent(data);
            } else if (chatMode === 'agent') {
                let data = {
                    affinityToken: laAffinityToken,
                    key: laSessionKey,
                    message: filteredMessage,
                    type: 'message',
                    username: username,
                    chatId: chatId,
                };

                if (
                    !respondedToAgent &&
                    chatCountReason != 'respondedToAgent'
                ) {
                    dispatch(
                        editSessions({
                            respondedToAgent: true,
                            chatCountReason: 'respondedToAgent',
                        })
                    );
                }

                socket.sendChasitorMessageEvent(data);
            }
        }
        setInputMessage('');
        setFilteredMessage('');
        closeIntentSuggestionModal();
    };

    let isOnComposition: boolean = false;

    const handleComposition = (e: React.CompositionEvent<HTMLInputElement>) => {
        // console.log(e);
        if (e.type === 'compositionend') {
            // composition is end
            isOnComposition = false;
        } else {
            // in composition
            isOnComposition = true;
        }
    };

    useEffect(() => {
        if (chatMode === 'agent') {
            if (isTyping && inputMessage.trim().length > 0) {
                clearTimeout(typingTimer);
                setTypingTimer(
                    setTimeout(() => {
                        socket.sendChasitorTypingEvent({
                            affinityToken: laAffinityToken,
                            key: laSessionKey,
                            text: messageToSend,
                            username: username,
                            chatId: chatId,
                        });
                    }, 2000)
                );
            } else {
                clearTimeout(typingTimer);
                socket.sendChasitorNotTypingEvent({
                    affinityToken: laAffinityToken,
                    key: laSessionKey,
                    text: '',
                    username: username,
                    chatId: chatId,
                });
            }
        }
    }, [inputMessage, chatMode]);

    useEffect(() => {
        if (chatMode == 'bot') {
            if (isTyping && inputMessage.trim().length >= 2) {
                clearTimeout(typingTimer);
                setTypingTimer(
                    setTimeout(() => {
                        getIntentList();
                    }, 500)
                )
            }
            else {
                clearTimeout(typingTimer);
            }
        }
    }, [inputMessage, chatMode]);

    const getIntentList = () => {
        console.log("test");
        const botAccountId = `${acctId}${botId}`;
        const search = inputMessage;
        var intentList:any = [];
        return new Promise(async (resolve, reject) => {
            try {
                const res:any = await post(`${apiPath}/getIntentInfo`, {
                    data: {
                        botAccountId,
                        search,
                    },
                });
                resolve(res);
                intentList = res;
                if (intentList && processor == 0) {
                    dispatch(
                        editSession({
                            name: 'intentSuggestion',
                            value: intentList,
                        })
                    );
                    openIntentSuggestionModal();
                }
            } catch (e) {
                error(
                    'Exception when getting intent info from cache:',
                    e
                );
                reject(e);
            }
        });
    };

    const handleInputChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let text = e.target.value;
        setInputMessage(text);
        if (text.length > 0) {
            setIsTyping(true);
        } else {
            setIsTyping(false);
        }
        const [filteredText, filteredTextAndRules]: any = await filter(
            regExp,
            filters,
            text,
            t('matchedFilterMessage')
        );

        const isString = (value: unknown): value is string => {
            return !!value && typeof value === 'string';
        };

        setFilteredMessage(
            isString(filteredTextAndRules) ? filteredTextAndRules : ''
        );

        setInputMessage(text);

        setMessageToSend(filteredText);


        if (text == '') {
            closeIntentSuggestionModal();
        }
    };

    const intentSelection = () => {
        if (intentChoice != '') {
            setIntentSent(intentSent + 1);
            setFilteredMessage(intentChoice);
            setMessageToSend(intentChoice);
            if (intentSent > 0 && messageToSend === intentChoice) {
                addUserMessage();
                closeIntentSuggestionModal();
            }
        }
    };

    intentSelection();

    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        if ((isValidInputMessage)) {
            addUserMessage();
        } 
    };

    const handleKeyDown = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'Enter') {
            if (inputMessage === '__warn') {
                dispatch(
                    addDialog({
                        task: 'timeoutWarning',
                        type: 'message',
                        message: 'Timeout',
                        actionMessage: 'Reconnect',
                    } as DialogInterface)
                );
            } else if (inputMessage === '__disconnect') {
                dispatch(
                    addDialog({
                        task: 'disconnected',
                        type: 'action',
                        message: 'You are disconnected',
                        actionMessage: 'Reconnect',
                    } as DialogInterface)
                );
            } else if (inputMessage === '__end') {
                dispatch(
                    addDialog({
                        task: 'ended',
                        type: 'action',
                        message: 'You are ended',
                        actionMessage: 'Reconnect',
                    } as DialogInterface)
                );
            } else if (inputMessage === '__timeout') {
                dispatch(
                    addDialog({
                        task: 'timeout',
                        type: 'action',
                        message: 'You are timeout',
                        actionMessage: 'Reconnect',
                    } as DialogInterface)
                );
            } else if (isValidInputMessage && inputMessage) {
                addUserMessage();
            } else {

            }
        }
    };

    const handleRatingClick = () => {
        if (chatRatingDone.toString() == 'true' && chatMode == 'bot') {

            Swal.fire({
                title: '',
                text: t('ratingSubmitted'),
                icon: 'success',
                confirmButtonText: t('ratingEndChat'),
                confirmButtonColor: botButtonColor
                    ? botButtonColor.slice(0, 7)
                    : '',
                showCancelButton: true,
                cancelButtonText: t('ratingContinueChat'),
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(addDialog({ task: 'leaved', type: 'action' }));
                    cacheService.endSession(sessionId);
                }
            });
        } else {

            if(chatMode === 'agent') {

                // Feedback form message is different for T1M1 bots
                let IsT1M1 = acctId.toString() + botId.toString();
                let T1M1ID = ["422", "430"];

                dispatch(
                    editStatus({
                        name: 'isChatRatingModal',
                        value: true,
                    })
                );
                

                // Swal.fire({
                //     title: '',
                //     text: T1M1ID.includes(IsT1M1) ? t('feedbackFormMessageAgentT1M1'): t('feedbackFormMessageAgent'),
                //     icon: 'info',
                //     confirmButtonText: t('feedbackFormAgent'),
                //     confirmButtonColor: botButtonColor
                //         ? botButtonColor.slice(0, 7)
                //         : '',
                //     showCancelButton: true,
                //     cancelButtonText: t('ratingContinueChat'),
                //     showDenyButton: true,
                //     denyButtonText: t('ratingEndChat'),
                // }).then((result) => {
                //     if (result.isConfirmed) {
        
                //         dispatch(
                //             editStatus({
                //                 name: 'isChatRatingModal',
                //                 value: true,
                //             })
                //         );
        
                //     } else if (result.isDenied) {
        
                //         dispatch(addDialog({ task: 'leaved', type: 'action' }));
                //         cacheService.endSession(sessionId);
        
                //       }
                // });


            } else {
                dispatch(
                    editStatus({
                        name: 'isChatRatingModal',
                        value: true,
                    })
                );
            }


            // dispatch(
            //     editStatus({
            //         name: 'isChatRatingModal',
            //         value: true,
            //     })
            // );
        }

        // dispatch(
        //     editStatus({
        //         name: 'isChatRatingModal',
        //         value: true,
        //     })
        // );
    };

    const handleUploadAgent = (event:any) => {

        let file = event.target.files[0];
        let filename = file['name'];
        let filesize = file['size'];
        let filetype = file["type"].split("/");

        console.log("FILE", event.target.files[0]);
        console.log("UPLOADED FILE",filename,filesize, filetype);

        const validFileTypes = ["jpg","png","jpeg" ,"gif","pdf"];

        let regexFile = /(?:\.([^.]+))?$/;

        let fileExtension = filename.match(regexFile);
        fileExtension = fileExtension[1].toLowerCase();

        console.log("FILE EXTENTION", fileExtension);

        let fileSizeLimit = 5 * 1048576;

        if (filesize > fileSizeLimit || !validFileTypes.includes(fileExtension)) {

            const wrongFileSizeMessage = {
                owner: 'system',
                name: 'Mario',
                type: 'text',
                message: {
                    content:
                        t("fileUploadInvalid"),
                },
                displayTime: format(new Date(), 'hh:mmaa'),
                createdBy: new Date().getTime(),
            } as ChatInterface;
            dispatch(addChat(wrongFileSizeMessage));
            
        } 
        else {
        
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function () {
                let data = {
                    "filename": filename,
                    "file": reader.result,
                    "url": "",
                    "orgId":  "",
                    "chatKey":  "",
                    "fileToken":  "",
                    "fileFlag" : "MARIO",
                    "encoding": 'UTF-8',
                    "chatId": chatId,
                }

                dispatch(removeDialog('uploadFile'));
                console.log("DATE HERE", data);
                socket.sendUploadFileEvent(data);

            }

        }

    }

    useEffect(() => {
        if (checkInputMessage(inputMessage)) {
            setIsValidInputMessage(true);

        } else {
            setIsValidInputMessage(false);
        }
    }, [inputMessage]);

    return (
        <div
            className="pb-[20px] px-2 small:pb-0 small:px-0 small:rounded-b-xl"
            style={{
                backgroundColor: botChatBgColor
                    ? botChatBgColor.slice(0, 7)
                    : '',
            }}
        >
            <div
                style={{
                    backgroundColor: botColor ? botColor.slice(0, 7) : '',
                }}
                className={classNames(
                    'h-[48px] sm:h-[52px] bg-slate-300 rounded-t-xl rounded-b-xl small:rounded-t-none flex px-2 items-center border-t-2 border-slate-200 relative shadow-md',
                    {
                        'pointer-events-none': !isChatAlive,
                        hidden: !isChatAlive,
                    }
                )}
            >
                <p className="text-black text-2xs bg-gray-300 absolute top-0 right-0">
                    {/* {props.debug} */}
                </p>
                {!isChatRatingSubmitted && (
                    <div
                        onClick={handleRatingClick}
                        className="h-full px-1 flex justify-center items-center z-10 hover:cursor-pointer"
                        //className={`h-full px-1 flex justify-center items-center z-10 hover:cursor-pointer ${isBotTyping && processor === 2 ? 'opacity-50 pointer-events-none' : ''}`}
                    >
                        <div
                            // style={{ backgroundColor: `${botCloseButtonColor}` }}
                            className={classNames(
                                'w-[32px] h-[32px] rounded-full bg-yellow-400 flex justify-center items-center'
                            )}
                        >
                            <FontAwesomeIcon
                                icon={faStar}
                                className="text-white font-medium text-[16px] small:text-base inline-block cursor-pointer"
                            />
                            {/* {botCloseButtonText && (
                    <div className="text-white text-lg whitespace-nowrap hover:cursor-pointer">
                        {botCloseButtonText}
                    </div>
                )}
                {!botCloseButtonText && (
                    <FontAwesomeIcon
                        icon={faCircleXmark}
                        className={classNames('text-xl flex-none z-10', {
                            'text-red-600/75': !botCloseButtonColor,
                            'text-white': botCloseButtonColor,
                        })}
                    />
                )} */}
                        </div>
                    </div>
                )}
                
                {chatMode === 'agent' && (

                    <div
                        className="h-full text-[24px] px-1 flex justify-center items-center z-10 hover:cursor-pointer"
                    > 

                        <label htmlFor="contained-button-file" className='cursor-pointer text-black'>

                        <FontAwesomeIcon
                                icon={faPaperclip}
                                className="text-black inline-block cursor-pointer"
                            />

                        </label>

                        <input
                            type="file"
                            className='hidden'
                            accept=".jpg, .png, .jpeg, .gif, .pdf"
                            id="contained-button-file"
                            onChange={handleUploadAgent}
                        />                        
                        
                    </div>

                )}
                

                <input
                    style={{
                        backgroundColor: botColor ? botColor.slice(0, 7) : '',
                    }}
                    type="search"
                    className={classNames("w-full grow h-full px-2 bg-slate-300 text-base focus:outline-none",
                        {
                            'border-2 border-red-300': !isValidInputMessage,
                        }
                    )}
                    onCompositionStart={handleComposition}
                    onCompositionUpdate={handleComposition}
                    onCompositionEnd={handleComposition}
                    onChange={handleInputChange}
                    onFocus={realign}
                    onClick={realign}
                    value={inputMessage}
                    onKeyDown={handleKeyDown}
                    placeholder={typingBarPlaceHolder}
                    autoComplete="off"
                    autoCorrect="off"
                    autoSave="off"
                    ref={inputRef}
                    maxLength={1000}
                    disabled={isBotTyping && processor === 2}
                />
                {/* <div className="w-10pc"></div> */}

                    
                <div className="min-w-10pc w-10pc h-full flex justify-center">
                    <Button
                        fns={
                            [
                                {
                                    type: 'js',
                                    func: handleClick,
                                },
                            ] as (JsFuntionInterface & ReduxFunctionInterface)[]
                        }
                        disabled={isBotTyping && processor === 2} // Add this line to disable the button
                        className={isBotTyping && processor === 2 ? 'opacity-50 pointer-events-none' : ''}
                    >
                        <img
                            className="w-6 h-6 small:w-6 small:h-6 large:w-8 large:h-8 hover:cursor-pointer"
                            src={require('../assets/send (2).png')}
                        ></img>
                    </Button>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {};

export default Footer;
